import React from "react";

import "semantic-ui-css/semantic.min.css";

import { Redirect } from "react-router-dom";

import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Tab
} from "semantic-ui-react";

import moment from "moment";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameInput: '',
      passwordInput: ''
    };
  }

  componentDidMount() {
      const username = this.props.getLS('username');
      const token = this.props.getLS('token');
      if(username !== undefined && username !== null && token !== undefined && token !== null) {
        this.handleLoginWithToken(username, token);
      }
  }

  handleFieldChange(field) {
    return (event) => {
      this.setState({
        [field]: event.target.value
      });
    }
  }

  handleLogin(event) {
    this.props.rest().post('login', {
        username: this.state.usernameInput,
        password: this.state.passwordInput
    }).then(result => {
        this.props.refreshToken(this.state.usernameInput, result.token, result.accessLevel);
    }).catch(e => {
        this.props.cleanLS();
        console.log(`ERR ${JSON.stringify(e)}`);
    })
  }

  handleLoginWithToken(username, token) {
    this.props.rest().post('login/check', {
      username,
      token
    }).then(result => {
      this.props.refreshToken(username, token, null);
    }).catch(e => {
      this.props.cleanLS();
      console.log(`ERR TOKEN ${JSON.stringify(e)}`);
    });
  }

  render() {
    if(this.props.loggedIn === true) {
      return <Redirect from="/login" to={this.props.to || '/'} />
    } else {
      return (
        <div className="LoginForm">
          <Grid textAlign="center" verticalAlign="middle">
            <Grid.Row>
            <Grid.Column style={{ maxWidth: 400, marginTop: '20px' }}>
              <div style={{
                backgroundColor: '#1678c2',
                borderRadius: '20px',
                padding: '10px',
                margin: 'auto',
                maxWidth: 200
              }}>
                <Image style={{padding: '5px'}} src='/logo.png' fluid />
              </div>
              <Divider hidden />

                    <Header as="h2" color="blue" textAlign="center">
                      ADMINISTRATION
                    </Header>
                    <Form size="large">
                      <Segment stacked>
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="E-mail"
                          value={this.state.usernameInput}
                          onChange={this.handleFieldChange('usernameInput').bind(this)}
                        />
                        <Form.Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          placeholder="Mot de passe"
                          type="password"
                          value={this.state.passwordInput}
                          onChange={this.handleFieldChange('passwordInput').bind(this)}
                        />
                        <Button color="blue" fluid size="large" onClick={this.handleLogin.bind(this)}>
                          S'identifier
                        </Button>
                      </Segment>
                    </Form>
            </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>  
      );
    }
  }
}

export default Login;