import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Modal,
  Table,
  Popup,
  Form,
  TextArea
} from "semantic-ui-react";
import resolvePath from 'object-resolve-path';
import randomstring from 'randomstring';
import moment from 'moment-timezone';
import { BOARDS, COMMERCIAL_STATUSES, DATA_STICKS, RFIDS, STATUSES } from './utils/constants';

const TZ_OPTS = moment.tz.names().map(x => ({
  key: `TZ_${x}`,
  text: x,
  value: x
}));

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      //dbStatuses: [],
      vpnClientList: [],
      availableRpiHardwares: [],
      prodRpiHardwares: [],
      traccarGroups: [],
      editingCustomer: {
        rpiHardware: {},
        phoneHardware: {}
      },
      error: undefined,
      sortState: {
        data: []
      },
      loading: false,
      visibleNotes: false,
      displayActiveAccounts: true,
      displayDemoAccounts: false,
      displayLockedAccounts: false,
      displayTerminatedAccounts: false,
      welcomeEmailLoading: false,
      welcomeEmailResult: undefined,
      welcomeEmailPopup: false,
      welcomeEmailHeader: "Madame, Monsieur,",
      welcomeEmailRecipients: "",
      megaFilter: "",
      displayMore: undefined,

      loadingVPNConfig: false,
      loadingDmesg: false,
      loadingPointages: false,
      loadingCmd: false,
      loadingWifi: false,
      loadingTZ: false,
      
      cmdPopupOpened: false,
      cmd: "",
      cmdout: "",
      cmdoutModalOpened: false,
      wifiPopupOpened: false,
      tzPopupOpened: false,

      wifiSettings: {
        ssid: "",
        pwd: ""
      },
      selectedTZ: "Europe/Paris",
    };
  }

  componentDidMount() {
    this.getCustomers();
  }

  getCustomers() {
    return new Promise((resolve, reject) => {
        this.setState({loading: true}, () => {
          resolve();
        })
    }).then(() => Promise.all([
        this.props.rest().get('clients'),
        //this.props.rest().get('database'),
        this.props.rest().get('openvpn/clientList'),
        this.props.rest().get('rpihardwares'),
        this.props.rest().get('phoneHardwares'),
        this.props.rest().get('traccar/groups')
    ]).then(([customers,/*dbStatuses,*/vpnClientList,rpiHardwares,phoneHardwares,traccarGroups]) => {
        this.setState({
          customers,
          //dbStatuses,
          vpnClientList,
          availableRpiHardwares: rpiHardwares.filter(x => x.status === 'IN_STOCK'),
          prodRpiHardwares: rpiHardwares.filter(x => x.status === 'PRODUCTION'),
          availablePhoneHardwares: phoneHardwares.filter(x => x.status === 'IN_STOCK'),
          prodPhoneHardwares: phoneHardwares.filter(x => x.status === 'PRODUCTION'),
          traccarGroups,
          sortState: Object.assign({}, this.state.sortState, {
            data: customers.slice().sort((c1, c2) => c1.id < c2.id ? 1 : -1),
            column: 'id',
            direction: 'descending'
          })
        });
    })).catch((e) => {
      this.manageErrors(e);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  manageErrors(err) {
    if(err !== undefined) {
      this.setState({
        error: err.entity
      });
      setTimeout(() => {
        this.setState({
          error: ""
        });
      }, 5000);
    }
  }

  onClickOnCustomerRow(customer) {
    return (event) => {
      if(customer.id !== this.state.editingCustomer.id) {
        this.setState({
          editingCustomer: Object.assign({}, customer, {
            subscriptionStart: customer.subscriptionStart !== null ? moment.unix(customer.subscriptionStart).format("DD/MM/YYYY") : ''
          })
        });
      }
    };
  }

  onClickOnAddCustomer(event) {
    this.props.rest().post('clients', {
      name: '- Nouveau Client',
      code: randomstring.generate({
        length: 8,
        charset: 'numeric'
      }),
      email: '',
      password: randomstring.generate({
        length: 8,
        charset: 'alphanumeric'
      }),
      accessToken: randomstring.generate({
        length: 64,
        charset: 'alphanumeric'
      }),
      rpiHardware: {},
      phoneHardware: {},
      traccarGroupId: 0,
      notes: ""
    }).then(customer => 
      this.props.rest().post('database', {id: customer.id})
        .then(result => 
          this.getCustomers().then(() => {
            this.setState({
              editingCustomer: Object.assign({}, customer, {rpiHardware: {}, phoneHardware: {}})
            });
          })
        )
    )
    .catch(e => this.manageErrors(e));
  }

  onClickOnUpdCustomer(customer) {
    return (event) => {
      this.props.rest().put('clients', customer).then(customer => {
        this.setState({
          editingCustomer: {rpiHardware: {}, phoneHardware: {}}
        }, () => {
          const currentStateCustomerIdx = this.state.customers.findIndex(x => x.id === customer.id);
          const currentStateCustomer = this.state.customers[currentStateCustomerIdx];
          const currentSortedStateCustomerIdx = this.state.sortState.data.findIndex(x => x.id === customer.id);
          const newStateCustomer = Object.assign({}, currentStateCustomer, customer);
          const newCustomerList = [...this.state.customers.slice(0, currentStateCustomerIdx), newStateCustomer, ...this.state.customers.slice(currentStateCustomerIdx+1)];
          const newCustomerSortedList = [...this.state.sortState.data.slice(0, currentSortedStateCustomerIdx), newStateCustomer, ...this.state.sortState.data.slice(currentSortedStateCustomerIdx+1)];
          this.setState({
            customers: newCustomerList,
            sortState: Object.assign({}, this.state.sortState, {
              data: newCustomerSortedList
            })
          }, () => {
            this.handleSort(null)();
          });
        });
      }).catch(e => this.manageErrors(e));
    };
  }

  onClickOnConnectToCustomerAccount(customerToken) {
    return (event) => {
      window.open(`https://orane.online/login?token=${customerToken}`, '_blank').focus();
    };
  }

  onClickOnDisplayMore(cId) {
    return (event) => {
      this.setState({displayMore: this.state.displayMore === cId ? undefined : cId});
    };
  }

  /*onClickOnDelCustomer(id) {
    return (event) => {
      delete('clients', {id}).then(result => {
        this.getCustomers();
      }).catch(e => this.manageErrors(e));
    };
  }*/

  onChangeCustomerProp(propName) {
    return (event, data) => {
      let usefulData = event.target.value;
      if(usefulData === undefined && data !== undefined) {
        usefulData = data.value;
      }
      this.setState({
        editingCustomer: Object.assign({}, this.state.editingCustomer, {[propName]: usefulData})
      });
    };
  }

  onChangeTraccarGroupId(e,data) {
    this.setState({
      editingCustomer: Object.assign({}, this.state.editingCustomer, {
        traccarGroupId: data.value
      })
    });
  }

  onChangeRpiHardwares(e,data) {
    this.setState({
      editingCustomer: Object.assign({}, this.state.editingCustomer, {
        rpiHardware: data.value.reduce((acc, x) => {
          const foundHardware = [...this.state.availableRpiHardwares, ...this.state.prodRpiHardwares].find((y) => y.name === x);
          if(foundHardware !== undefined) {
            return Object.assign(acc, {
              [x]: foundHardware.token
            });
          } else {
            return acc;
          }
        }, {})
      })
    });
  }

  onChangePhoneHardwares(e,data) {
    const oldPhoneHardware = this.state.editingCustomer.phoneHardware;
    const newPhoneHardware = data.value.reduce((acc, imei) => {
      const oldImeis = Object.keys(oldPhoneHardware);
      if(oldImeis.includes(imei)) {
        return Object.assign(acc, {[imei]: oldPhoneHardware[imei]});
      } else {
        const foundPhone = [...this.state.availablePhoneHardwares, ...this.state.prodPhoneHardwares].find((y) => y.imei === imei);
        if(foundPhone !== undefined) {
          return Object.assign(acc, {[imei]: {customName: foundPhone.customName || "", simNumber: foundPhone.simNumber || ""}});
        } else {
          return acc;
        }
      }
    }, {});
    this.setState({
      editingCustomer: Object.assign({}, this.state.editingCustomer, {
        phoneHardware: newPhoneHardware
      })
    });
  }

  handleSort(clickedColumn) {
    return () => {
      const { column, data, direction } = this.state.sortState;
      if (column !== clickedColumn || clickedColumn === null) {
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            column: clickedColumn !== null ? clickedColumn : column,
            data: data.sort((d1,d2) => resolvePath(d1, clickedColumn !== null ? clickedColumn : column) < resolvePath(d2, clickedColumn !== null ? clickedColumn : column) ? (direction === 'ascending' ? -1 : 1) : (direction === 'ascending' ? 1 : -1))
          })
        });
      } else {
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
            column
          })
        });
      }
    }
  }

  onClickOnSendWelcomeEmail(customerId) {
    return (evt) => {
      this.setState({welcomeEmailLoading: customerId, welcomeEmailPopup: false}, () => {
        this.props.rest().post('database/sendWelcomeEmail', {id: customerId, header: this.state.welcomeEmailHeader, recipients: this.state.welcomeEmailRecipients})
        .then((result) => {
          const customer = this.state.customers.find((x) => x.id === customerId);
          this.setState({
            welcomeEmailResult: customerId,
            customers: [...this.state.customers.filter(x => x.id !== customerId), Object.assign({}, customer, {
              welcomeEmailsHistory: [...customer.welcomeEmailsHistory, ({header: this.state.welcomeEmailHeader, recipients: this.state.welcomeEmailRecipients, timestamp: moment().unix()})]
            })]
          });
        }).catch((err) => {
          this.setState({welcomeEmailResult: false});
        }).finally(() => this.setState({welcomeEmailLoading: false, welcomeEmailHeader: 'Madame, Monsieur,', welcomeEmailRecipients: ''}))
      })
    };
  }

  onCopy(content) {
    return (evt) => {
      this.writeToClipboardIfPossible(content || "");
    };
  }
  
  onClickOnCopyConfig(rpiH) {
    return (evt) => {
      this.setState({loadingVPNConfig: rpiH.name}, () => {
        this.props.rest().get(`openvpn/config/${rpiH.name}`)
          .then(vpnConfig => {
            this.setState({
              rpiHardwares: this.state.rpiHardwares.map(x => x.name === rpiH.name ? Object.assign({}, rpiH, {config: Object.assign({}, rpiH.config, vpnConfig)}) : x)
            }, () => {
              const toCopyObj = (this.state.rpiHardwares.find(x => x.name === rpiH.name) || {}).config || {};
              this.onCopy(JSON.stringify(toCopyObj, null, 2))(evt);
            });
          }).catch((e) => {
            this.manageErrors(e);
          }).finally(() => {
            this.setState({loadingVPNConfig: false});
          });
        });
    };
  }

  onClickOnDmesg(rpiH) {
    return (evt) => {
      const cmd = `echo 'Numéro de série'; echo '-----------------------'; cat /etc/hostname; echo ''; echo ''; echo 'Uptime'; echo '-----------------------'; uptime -p; echo ''; echo 'Token'; echo '-----------------------'; cat /etc/orane; echo ''; echo ''; echo 'Date et heure de la machine'; echo '-----------------------'; date; echo ''; echo 'Liste des périphériques IO'; echo '-----------------------'; ls -al /dev/input/by-id/*; echo ''; echo 'Liste des périphériques série'; echo '-----------------------'; ls -al /dev/serial/by-id/*; echo ''; echo 'Interfaces réseau'; echo '-----------------------'; ifconfig -a; echo ''; echo 'Liste des réseaux wifi détectés'; echo '-----------------------'; iwlist wlan0 scan; echo ''; echo 'Log du logiciel'; echo '-----------------------'; cat /home/alarm/point/point.log; echo ''; echo 'Log de WVDial'; echo '-----------------------'; cat /home/alarm/wvdial.log; echo ''; echo 'Log du VPN'; echo '-----------------------'; cat /home/alarm/openvpn.log; echo ''; echo 'DMESG'; echo '-----------------------'; sudo dmesg; echo ''`;
      this.setState({cmd, loadingDmesg: rpiH.name}, () => {
        this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
          this.setState({loadingDmesg: false});
        });
      });
    };
  }

  onClickOnShowWifiSettings(rpiH) {
    return (evt) => {
      this.setState({wifiPopupOpened: false}, () => {
        const wifiInterface = 'wlan0';
        this.setState({cmd: `sudo cat /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf; sudo iwlist ${wifiInterface} scan`, loadingWifi: rpiH.name}, () => {
          this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
            this.setState({loadingWifi: false});
          });
        });
      });
    };
  }

  onClickOnSetWifiSettings(rpiH) {
    return (evt) => {
      this.setState({wifiPopupOpened: false}, () => {
        const {ssid, pwd} = this.state.wifiSettings;
        const wifiInterface = 'wlan0';
        const cmd = `sudo systemctl enable wpa_supplicant@${wifiInterface}; sudo systemctl enable dhcpcd@${wifiInterface}; sudo ifconfig ${wifiInterface} up; sudo cp /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf.defaults /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf && sudo chmod o+w /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf && sudo wpa_passphrase '${ssid}' '${pwd}' >> /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf; sudo chmod o-w /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf && sudo cat /etc/wpa_supplicant/wpa_supplicant-${wifiInterface}.conf && sudo systemctl restart wpa_supplicant@${wifiInterface}; sleep 2; sudo systemctl restart dhcpcd@${wifiInterface}; sudo systemctl status wpa_supplicant@${wifiInterface}`;
        this.setState({cmd, loadingWifi: rpiH.name}, () => {
          this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
            this.setState({loadingWifi: false});
          });
        });
      });
    };
  }

  onClickOnShowTZSettings(rpiH) {
    return (evt) => {
      this.setState({tzPopupOpened: false}, () => {
        this.setState({cmd: `readlink -f /etc/localtime`, loadingTZ: rpiH.name}, () => {
          this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
            this.setState({loadingTZ: false});
          });
        });
      });
    };
  }

  onClickOnSetTZSettings(rpiH) {
    return (evt) => {
      this.setState({tzPopupOpened: false}, () => {
        const cmd = `sudo rm -f /etc/localtime && sudo ln -s /usr/share/zoneinfo/${this.state.selectedTZ} /etc/localtime`;
        this.setState({cmd, loadingTZ: rpiH.name}, () => {
          this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
            this.setState({loadingTZ: false});
          });
        });
      });
    };
  }

  onClickOnGetPointages(rpiH) {
    return (evt) => {
      this.setState({cmd: "cat /home/alarm/point/server/data/pointages.json", loadingPointages: rpiH.name}, () => {
        this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
          this.setState({loadingPointages: false});
        });
      });
    };
  }

  onClickOnCmd(rpiH) {
    return (evt) => {
      this.setState({loadingCmd: rpiH.name}, () => {
        this.requestExecCmd(rpiH.name, this.state.cmd).finally(() => {
          this.setState({loadingCmd: false, cmdPopupOpened: false});
        });
      });
    };
  }

  requestExecCmd(rpiHName, cmd) {
    return this.props.rest().post(`openvpn/cmd/${rpiHName}`, {cmd})
      .then((result) => {
        const {stdout} = result;
        this.setState({
          cmdout: stdout,
          cmdoutModalOpened: rpiHName
        });
      }).catch((e) => {
        this.manageErrors(e);
      });
  }
  
  handleCmdChange(evt) {
    this.setState({cmd: evt.target.value});
  }

  handleWifiSettingsChange(setting) {
    return (evt) => {
      this.setState({wifiSettings: Object.assign(this.state.wifiSettings, {
        [setting]: evt.target.value
      })});
    };
  }

  render() {
    const customerTypes = [['ACTIVE', 'Actif'], ['LOCKED', 'Verrouillé'], ['DEMO', 'Démo'], ['TERMINATED', 'Résilié']];
    const statuses = STATUSES;
    const commercialStatuses = COMMERCIAL_STATUSES;
    const dataSticks = DATA_STICKS;
    const boards = BOARDS;
    const rfids = RFIDS;

    const filteredCustomers = this.state.sortState.data
    .filter(c => this.state.displayActiveAccounts === false ? c.type !== 'ACTIVE' : true)
    .filter(c => this.state.displayDemoAccounts === false ? c.type !== 'DEMO' : true)
    .filter(c => this.state.displayLockedAccounts === false ? c.type !== 'LOCKED' : true)
    .filter(c => this.state.displayTerminatedAccounts === false ? c.type !== 'TERMINATED' : true)
    .filter(c => this.state.megaFilter.length > 0 ?
      c.id.toString().toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.name.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.email.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.code.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      c.notes.toLowerCase().includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.rpiHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => k.toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].imei || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase()) ||
      Object.keys(c.phoneHardware).map(k => (c.phoneHardware[k].simNumber || "").toLowerCase()).reduce((acc, x) => `${acc}${x} `, "").includes(this.state.megaFilter.toLowerCase())
    : true);

    const getCmdPopup = (rpiH) => {
      return <Popup
        content={
          <Input onChange={this.handleCmdChange.bind(this)} value={this.state.cmd} fluid placeholder='ls -al' action={
            <Button.Group>
              <Button color='red' loading={this.state.loadingCmd === rpiH.name} onClick={this.onClickOnCmd(rpiH).bind(this)}><Icon name='play' /></Button>,
              <Button icon='close' style={{float: 'right'}} onClick={() => this.setState({cmdPopupOpened: false})} />
            </Button.Group>
          } />
        }
        open={this.state.cmdPopupOpened === rpiH.name}
        flowing
        position='bottom right'
        header={<span>Entrez votre commande dans le champ ci-dessous puis appuyez sur le bouton rouge pour exécuter ou sur la croix pour fermer</span>}
        on='click'
        pinned
        trigger={
          <span data-tooltip="Exécuter une commande arbitraire" data-position="bottom right"><Button color='red' disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={() => this.setState({cmdPopupOpened: this.state.cmdPopupOpened === rpiH.name ? false : rpiH.name})}><Icon name='keyboard outline' /></Button></span>
        }
      />;
    }

    const getWifiModal = () => {
      const rpiH = {name: this.state.wifiPopupOpened || ''};
      return <Modal closeIcon closeOnDimmerClick={false} onClose={() => this.setState({wifiPopupOpened: false})} open={this.state.wifiPopupOpened !== false}>
        <Header icon='wifi' content='Configuration du wifi de la machine' />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Label>Voir la config. wifi actuelle</Label>
              </Grid.Column>
              <Grid.Column width={8}></Grid.Column>
              <Grid.Column width={2}>
                <Button size='mini' color='green' loading={this.state.loadingCmd === rpiH.name} onClick={this.onClickOnShowWifiSettings(rpiH).bind(this)}><Icon name='play' /></Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <Label>Modifier la config.</Label>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input fluid placeholder='SSID' value={this.state.wifiSettings.ssid} onChange={this.handleWifiSettingsChange('ssid').bind(this)} />
              </Grid.Column>
              <Grid.Column width={5}>
                <Input fluid placeholder='Mot de passe' value={this.state.wifiSettings.pwd} onChange={this.handleWifiSettingsChange('pwd').bind(this)} />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button size='mini' color='green' loading={this.state.loadingCmd === rpiH.name} onClick={this.onClickOnSetWifiSettings(rpiH).bind(this)}><Icon name='play' /></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
        </Modal.Actions>
      </Modal>;
    }

    const getTimezoneModal = () => {
      const rpiH = {name: this.state.tzPopupOpened || ""};
      return <Modal closeIcon closeOnDimmerClick={false} onClose={() => this.setState({tzPopupOpened: false})} open={this.state.tzPopupOpened !== false}>
        <Header icon='wifi' content='Configuration du fuseau horaire de la machine' />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Label>Voir le fuseau horaire actuel</Label>
              </Grid.Column>
              <Grid.Column width={8}></Grid.Column>
              <Grid.Column width={2}>
                <Button size='mini' color='green' loading={this.state.loadingCmd === rpiH.name} onClick={this.onClickOnShowTZSettings(rpiH).bind(this)}><Icon name='play' /></Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <Label>Modifier la config.</Label>
              </Grid.Column>
              <Grid.Column width={10}>
                <Dropdown
                  lazyLoad
                  placeholder='Fuseau horaire'
                  search
                  selection
                  onChange={(evt, data) => this.setState({selectedTZ: data.value})}
                  value={this.state.selectedTZ}
                  options={TZ_OPTS}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button size='mini' color='green' loading={this.state.loadingCmd === rpiH.name} onClick={this.onClickOnSetTZSettings(rpiH).bind(this)}><Icon name='play' /></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
        </Modal.Actions>
      </Modal>;
    }

    return (
        <Container className="adminContentClass" textAlign="center">
          <Modal size='large' open={this.state.cmdoutModalOpened !== false}>
            <Modal.Header icon='list alternate outline' content={`Résultat de la commande ${this.state.cmd} pour ${this.state.cmdoutModalOpened}`} />
            <Modal.Content style={{minHeight: '50vh'}} scrolling>
              <Form>
                <TextArea style={{minHeight: '50vh'}} placeholder="Oups...la sortie est vide..." value={this.state.cmdout} />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' onClick={() => this.setState({cmdoutModalOpened: false})}>
                <Icon name='close' /> Fermer
              </Button>
              <Button color='blue' onClick={this.onCopy(this.state.cmdout).bind(this)}>
                <Icon name='copy outline' /> Copier dans le presse-papiers
              </Button>
            </Modal.Actions>
          </Modal>
          {
            <Grid padded>
              <Grid.Row style={{
                  backgroundColor: '#545454',
                  borderRadius: '10px'
                }}>
                <Grid.Column width={2}>
                  <Icon name='database' size='huge' inverted color='blue' circular />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={10} verticalAlign="middle" textAlign="center">
                  <Header size="huge" as="h1" inverted>
                    <Header.Content>
                      Configuration des comptes clients
                      <Header.Subheader>
                        Attention à ce que vous faites ! Ne modifiez rien sans comprendre.
                      </Header.Subheader>
                      <br />
                      <Header.Subheader>
                        <Grid columns={16}>
                        <Grid.Row>
                            <Grid.Column width={1}></Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayActiveAccounts} onChange={() => {
                                this.setState({displayActiveAccounts: !this.state.displayActiveAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases actives</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayDemoAccounts} onChange={() => {
                                this.setState({displayDemoAccounts: !this.state.displayDemoAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases de démo</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayLockedAccounts} onChange={() => {
                                this.setState({displayLockedAccounts: !this.state.displayLockedAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases verrouillées</Label>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.displayTerminatedAccounts} onChange={() => {
                                this.setState({displayTerminatedAccounts: !this.state.displayTerminatedAccounts});
                              }} />
                              <br /><br />
                              <Label>Afficher les bases résiliées</Label>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Checkbox style={{verticalAlign: 'middle'}} toggle checked={this.state.visibleNotes} onChange={() => {
                                this.setState({visibleNotes: !this.state.visibleNotes});
                              }} />
                              <br /><br />
                              <Label>Afficher les notes</Label>
                            </Grid.Column>
                            <Grid.Column width={1}></Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header.Subheader>
                    </Header.Content>  
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Button data-tooltip="Ajouter un client" data-position="bottom right" icon color='green' onClick={this.onClickOnAddCustomer.bind(this)}>
                    <Icon name='plus' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {this.state.error}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Input
                    placeholder='Recherche libre'
                    icon='search'
                    fluid
                    onChange={(evt) => this.setState({megaFilter: evt.target.value})}
                    value={this.state.megaFilter}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign: 'left'}} width={2}>
                  <Label size='large'>{filteredCustomers.length} ligne(s) trouvée(s)</Label>
                </Grid.Column>
                <Grid.Column width={14}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{marginBottom: '100px'}}>
                {
                this.state.loading === true ?
                <Dimmer inverted active>
                  <Loader inverted />
                </Dimmer>
                :
                <Table striped selectable sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={this.state.sortState.column === 'id' ? this.state.sortState.direction : null}
                        onClick={this.handleSort('id').bind(this)}>
                        ID
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'name' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('name').bind(this)}>
                        Nom
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'code' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('code').bind(this)}>
                        Code
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'subscriptionStart' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('subscriptionStart').bind(this)}>
                        Date de souscription
                      </Table.HeaderCell>
                      <Table.HeaderCell
                      sorted={this.state.sortState.column === 'email' ? this.state.sortState.direction : null}
                      onClick={this.handleSort('email').bind(this)}>
                        Email
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        MDP
                      </Table.HeaderCell>
                      <Table.HeaderCell>Pointeuses</Table.HeaderCell>
                      <Table.HeaderCell>Téléphones</Table.HeaderCell>
                      <Table.HeaderCell>Applis.</Table.HeaderCell>
                      <Table.HeaderCell>Groupe Traccar</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      filteredCustomers
                      .map(c => (
                        [<Table.Row positive={this.state.displayMore === c.id} key={`details_customer_${c.id}`} onClick={this.onClickOnCustomerRow(c).bind(this)}>
                          <Table.Cell>{c.id}</Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Nom...' value={this.state.editingCustomer.name} onChange={this.onChangeCustomerProp('name').bind(this)} />
                            : c.name
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Code...' value={this.state.editingCustomer.code} onChange={this.onChangeCustomerProp('code').bind(this)} />
                            : c.code
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='DD/MM/YYYY' value={this.state.editingCustomer.subscriptionStart !== null ? this.state.editingCustomer.subscriptionStart : ''} onChange={this.onChangeCustomerProp('subscriptionStart').bind(this)} />
                            : c.subscriptionStart === null ? '' : moment.unix(c.subscriptionStart).format("DD/MM/YYYY")
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Email...' value={this.state.editingCustomer.email} onChange={this.onChangeCustomerProp('email').bind(this)} />
                            : c.email
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input type="password" fluid placeholder='Mot de passe...' value={this.state.editingCustomer.password} onChange={this.onChangeCustomerProp('password').bind(this)} />
                            : "(masqué)"
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                              <Dropdown
                                placeholder='BMO-XXXX-YYYY'
                                search
                                fluid
                                selection
                                multiple
                                onChange={this.onChangeRpiHardwares.bind(this)}
                                value={Object.keys(this.state.editingCustomer.rpiHardware)}
                                options={[...this.state.availableRpiHardwares.map(x => x.name), ...Object.keys(this.state.editingCustomer.rpiHardware)].map(x => ({
                                  key: x,
                                  text: x,
                                  value: x
                                }))}
                              /> :
                              Object.keys(c.rpiHardware).map((rh, idx) =>
                                <Label data-tooltip={this.state.vpnClientList.includes(rh) ? "En ligne" : "Hors ligne"} data-position="bottom right" color={this.state.vpnClientList.includes(rh) ? "green" : "red"} key={`rpi_${c.id}_${idx}`} size='tiny' as='span'>{rh}</Label>
                              )
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              this.state.editingCustomer.id === c.id ?
                                <Dropdown
                                  placeholder='3142XXXXXXX'
                                  search
                                  fluid
                                  selection
                                  multiple
                                  onChange={this.onChangePhoneHardwares.bind(this)}
                                  value={Object.keys(this.state.editingCustomer.phoneHardware)}
                                  options={[...this.state.availablePhoneHardwares.map(x => x.imei), ...Object.keys(this.state.editingCustomer.phoneHardware)].map(x => ({
                                    key: x,
                                    text: x,
                                    value: x
                                  }))}
                                /> :
                                Object.keys(c.phoneHardware).map((ph, idx) =>
                                  <Label data-tooltip={c.phoneHardware[ph].simNumber && c.phoneHardware[ph].simNumber !== "" ? `Sim : ${c.phoneHardware[ph].simNumber}` : "Pas de numéro de sim renseigné"} data-position="bottom right" key={`phone_${c.id}_${idx}`} size='tiny' as='span'>{ph}</Label>
                                )
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Nombre...' value={this.state.editingCustomer.phoneSoftware} onChange={this.onChangeCustomerProp('phoneSoftware').bind(this)} />
                            : c.phoneSoftware
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              this.state.editingCustomer.id === c.id ?
                                <Dropdown
                                  placeholder='ID de groupe'
                                  search
                                  fluid
                                  selection
                                  onChange={this.onChangeTraccarGroupId.bind(this)}
                                  value={this.state.editingCustomer.traccarGroupId}
                                  options={[{id: 0, name: 'Non assigné'}, ...this.state.traccarGroups].map(x => ({
                                    key: `traccarGroup_${x.id}`,
                                    text: x.name,
                                    value: x.id
                                  }))}
                                /> :
                                <Label size='tiny' as='span'>{(this.state.traccarGroups.find(x => x.id === c.traccarGroupId) || {name: "Non assigné"}).name}</Label>
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              this.state.editingCustomer.id === c.id ?
                                <Dropdown
                                  placeholder='Type'
                                  search
                                  fluid
                                  selection
                                  onChange={this.onChangeCustomerProp('type').bind(this)}
                                  value={this.state.editingCustomer.type}
                                  options={customerTypes.map(x => (
                                    {
                                      key: x[0],
                                      text: x[1],
                                      value: x[0]
                                    }
                                  ))}
                                />
                              : (customerTypes.find(x => x[0] === c.type) !== undefined ? customerTypes.find(x => x[0] === c.type)[1] : c.type)
                            }
                          </Table.Cell>
                          <Table.Cell textAlign='right' width={2}>
                            <Button data-tooltip="Enregistrer le client" data-position="bottom right" icon color='green' disabled={this.state.editingCustomer.id !== c.id} onClick={this.onClickOnUpdCustomer(Object.assign({}, this.state.editingCustomer, {subscriptionStart: this.state.editingCustomer.subscriptionStart !== '' ? moment(this.state.editingCustomer.subscriptionStart, "DD/MM/YYYY").unix() : null})).bind(this)}>
                              <Icon name='check' />
                            </Button>
                            <Popup
                              content={
                                <span>
                                  <Input label={<Label>Destinataires séparés par des ;</Label>} onChange={(e) => this.setState({welcomeEmailRecipients: e.target.value || c.email})} fluid placeholder="Destinataires séparés par des ;" value={this.state.welcomeEmailRecipients || ""} />
                                  <Input label={<Label>Première ligne de l'email</Label>} onChange={(e) => this.setState({welcomeEmailHeader: e.target.value || "Madame, Monsieur,"})} fluid placeholder="Première ligne de l'email" value={this.state.welcomeEmailHeader || ""} />
                                  <Button.Group>
                                    <Button color='red' onClick={this.onClickOnSendWelcomeEmail(c.id).bind(this)}><Icon name='play' /></Button>,
                                    <Button icon='close' style={{float: 'right'}} onClick={() => this.setState({welcomeEmailPopup: false})} />
                                  </Button.Group>
                                </span>
                              }
                              open={this.state.welcomeEmailPopup === c.id}
                              flowing
                              position='bottom right'
                              header={<span>Entrez les paramètres souhaités ci-dessous puis appuyez sur le bouton rouge pour envoyer ou la croix pour annuler.</span>}
                              on='click'
                              pinned
                              onOpen={() => this.setState({welcomeEmailRecipients: c.email})}
                              trigger={
                                <span data-tooltip={this.state.welcomeEmailResult === undefined || this.state.welcomeEmailResult !== c.id ? `Envoyer le mail de bienvenue (envoyé ${c.welcomeEmailsHistory.length} fois, dernière fois : ${c.welcomeEmailsHistory.slice(-1)[0] ? 'le ' + moment.unix(c.welcomeEmailsHistory.slice(-1)[0].timestamp).format('DD/MM/YYYY à HH:mm:ss') : 'jamais'})` : this.state.welcomeEmailResult ? "L'email a bien été envoyé" : "Une erreur est survenue lors de l'envoi"} data-position="bottom right">
                                  <Button icon color={this.state.welcomeEmailResult === undefined || this.state.welcomeEmailResult !== c.id ? 'orange' : this.state.welcomeEmailResult ? 'green' : 'red'} loading={this.state.welcomeEmailLoading === c.id} onClick={() => this.setState({welcomeEmailPopup: c.id})}>
                                    <Icon name='mail' />&nbsp;&nbsp;&nbsp;{c.welcomeEmailsHistory.length}
                                  </Button>
                                </span>
                              }
                            />
                            <Button data-tooltip="Se connecter sur le compte du client" data-position="bottom right" icon color='blue' onClick={this.onClickOnConnectToCustomerAccount(c.accessToken).bind(this)}>
                              <Icon name='lock' />
                            </Button>
                            <Button data-tooltip={this.state.displayMore === c.id ? 'Masquer les détails' : 'Afficher plus de détails'} data-position="bottom right" icon color={this.state.displayMore === c.id ? 'red' : 'green'} onClick={this.onClickOnDisplayMore(c.id).bind(this)}>
                              <Icon name='ellipsis vertical' />
                            </Button>
                            {
                              /*
                            <Button data-tooltip="Supprimer le client" disabled data-position="bottom right" icon color='red'>
                              <Icon name='trash' />
                            </Button>
                              */
                            }
                          </Table.Cell>
                        </Table.Row>,
                        this.state.displayMore === c.id ? this.state.prodRpiHardwares.filter(rh => Object.keys(c.rpiHardware).includes(rh.name)).map((rpiH, rhIdx) => <Table.Row positive key={`displayMore_customer_${c.id}_${rhIdx}`}>
                            <Table.Cell style={{textDecoration: 'underline'}}>{`#${rhIdx}`}</Table.Cell>
                            <Table.Cell><Label color={this.state.vpnClientList.includes(rpiH.name) ? 'green' : 'red'}>{rpiH.name}</Label></Table.Cell>
                            <Table.Cell>{boards.map(x => x.value).includes(rpiH.board) ? boards.find(x => x.value === rpiH.board).text : rpiH.board}</Table.Cell>
                            <Table.Cell>{rfids.map(x => x.value).includes(rpiH.rfid) ? rfids.find(x => x.value === rpiH.rfid).text : rpiH.rfid}</Table.Cell>
                            <Table.Cell>{dataSticks.map(x => x.value).includes(rpiH.dataStick) ? dataSticks.find(x => x.value === rpiH.dataStick).text : rpiH.dataStick}</Table.Cell>
                            <Table.Cell>{rpiH.simNumber}</Table.Cell>
                            <Table.Cell>{rpiH.status === 'PRODUCTION' ? 'En prod' : statuses.map(x => x.value).includes(rpiH.status) ? statuses.find(x => x.value === rpiH.status).text : rpiH.status} ({commercialStatuses.map(x => x.value).includes(rpiH.commercialStatus) ? commercialStatuses.find(x => x.value === rpiH.commercialStatus).text : rpiH.commercialStatus})</Table.Cell>
                            <Table.Cell textAlign='right' colSpan='5'>
                              <Button.Group>
                                <span data-tooltip="Copier la config de prod dans le presse-papiers" data-position="bottom right"><Button color='blue' loading={this.state.loadingVPNConfig === rpiH.name} onClick={this.onClickOnCopyConfig(rpiH).bind(this)}><Icon name='copy outline' /></Button></span>
                                <span data-tooltip="Voir le fichier des pointages" data-position="bottom right"><Button color='teal' loading={this.state.loadingPointages === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={this.onClickOnGetPointages(rpiH).bind(this)}><Icon name='clock outline' /></Button></span>
                                <span data-tooltip="Voir les réglages wifi" data-position="bottom right"><Button color='pink' loading={this.state.loadingWifi === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={() => this.setState({wifiPopupOpened: this.state.wifiPopupOpened === rpiH.name ? false : rpiH.name})}><Icon name='wifi' /></Button></span>
                                <span data-tooltip="Voir les réglages de fuseau horaire" data-position="bottom right"><Button color='olive' loading={this.state.loadingTZ === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={() => this.setState({tzPopupOpened: this.state.tzPopupOpened === rpiH.name ? false : rpiH.name})}><Icon name='clock' /></Button></span>
                                <span data-tooltip="Voir le rapport de diagnostic" data-position="bottom right"><Button color='orange' loading={this.state.loadingDmesg === rpiH.name} disabled={this.state.vpnClientList.includes(rpiH.name) === false} onClick={this.onClickOnDmesg(rpiH).bind(this)}><Icon name='list alternate outline' /></Button></span>
                                {getCmdPopup(rpiH)}
                              </Button.Group>
                            </Table.Cell>
                        </Table.Row>) : null,
                        this.state.visibleNotes || this.state.displayMore === c.id ? <Table.Row key={`notes_customer_${c.id}`} positive={this.state.displayMore === c.id} onClick={this.onClickOnCustomerRow(c).bind(this)}>
                          <Table.Cell style={{textDecoration: 'underline'}}>Notes</Table.Cell>
                          <Table.Cell colSpan={11}>
                            {
                            this.state.editingCustomer.id === c.id ?
                            <Input fluid placeholder='Notes...' value={this.state.editingCustomer.notes} onChange={this.onChangeCustomerProp('notes').bind(this)} />
                            : c.notes || ""
                            }
                          </Table.Cell>
                      </Table.Row> : null,
                      ]).flat().filter(x => x !== null))
                    }
                  </Table.Body>
                </Table>
                }
              </Grid.Row>
            </Grid>
          }
          {getWifiModal()}
          {getTimezoneModal()}
        </Container>
    );
  }
}

export default Customers;
